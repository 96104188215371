import React, { useState } from 'react';
import {
  UnstyledButton,
  Tooltip,
  Title,
  rem,
  Button,
  Popover,
  ActionIcon,
  Stack,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import styles from './layout.module.scss';
import Logo from '../../app/providers/assets/Vector.svg';
import { useAppDispatch } from 'app/providers/store/config/hooks';
import { authActions } from 'modules/user/containers/auth-container/auth-container.slice';
import { storage } from 'shared/utils/storage_news';
import { mainLinksMockdata, MainLinkItem, LinkItem } from './left-page.contans';
import { routes } from 'shared/constants';
import { useTranslation } from 'react-i18next';
import {
  IconArrowBigLeft,
  IconLanguage,
} from '@tabler/icons-react';
import { setLanguage } from 'app/providers/store/config/language-reducer/language.slice';

export function LeftPageMenu() {
  const [active, setActive] = useState<string>('Guests');
  const [activeLink, setActiveLink] = useState<string>('');
  const [menuOpen, setMenuOpen] = useState<boolean>(true);
  const [languagePopoverOpened, setLanguagePopoverOpened] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);

  const currentLanguage = i18n.language;

  const linksMockdata: Record<string, LinkItem[]> = {
    Home: [
      { label: 'Home', link: routes.home },
      { label: 'Home1', link: routes.home },
    ],
    Guests: [{ label: `${t('База гостей')}`, link: routes.guests }],
  };

  const logout = () => {
    dispatch(authActions.logout());
    storage.clear();
    navigate(routes.auth);
  };

  const handleMainLinkClick = (link: MainLinkItem) => {
    if (link.label === 'Logout') {
      logout();
    } else {
      setActive(link.label);
      setActiveLink('');
      setMenuOpen(true);
      if (link.link) {
        navigate(link.link);
      }
    }
  };

  const handleLinkClick = (
    event: React.MouseEvent<HTMLAnchorElement>,
    link: LinkItem,
  ) => {
    if (link.label === 'Logout') {
      logout();
    } else {
      event.preventDefault();
      setActiveLink(link.label);
      if (link.link) {
        navigate(link.link);
      }
    }
  };

  const changeLanguage = (lng: string) => {
    const newLng = lng === 'ru' ? 'ru-RU' : 'en-EN';
    i18n.changeLanguage(newLng);
    dispatch(setLanguage(newLng));
    storage.setItem('i18nextLng', newLng);
    setLanguagePopoverOpened(false);
  };

  const topLinks = mainLinksMockdata.slice(0, -1);
  const bottomLinks = mainLinksMockdata.slice(-1);

  const renderMainLinks = (links: MainLinkItem[]) =>
    links.map(link => (
      <Tooltip
        key={link.label}
        label={link.label}
        position='right'
        withArrow
        transitionProps={{ duration: 0 }}
      >
        <UnstyledButton
          onClick={() => handleMainLinkClick(link)}
          className={styles.mainLink}
          data-active={link.label === active || undefined}
        >
          <link.icon  stroke={2} color='#1F1F1F' size={19}/>
        </UnstyledButton>
      </Tooltip>
    ));

  const renderLinks = (links: LinkItem[]) =>
    links.map(link => (
      <a
        key={link.label}
        className={styles.link}
        data-active={activeLink === link.label || undefined}
        href='#'
        onClick={event => handleLinkClick(event, link)}
      >
        {link.label}
      </a>
    ));

  return (
    <nav
      className={`${styles.navbar} ${menuOpen ? styles.open : styles.closed}`}
    >
      <div className={styles.wrapper}>
        <div className={styles.aside}>
          <div className={styles.logo}>
            <Logo />
          </div>
          {renderMainLinks(topLinks)}

          <div className={styles.bottomLinks}>
            <Popover
              opened={languagePopoverOpened}
              onChange={setLanguagePopoverOpened}
              position='right'
              withArrow
              shadow='md'
            >
              <Popover.Target>
                <ActionIcon
                  onClick={() => setLanguagePopoverOpened(o => !o)}
                  size='lg'
                  style={{ width: '44px', height: '44px' }}
                >
                  <IconLanguage size={19} stroke={2} color='#1F1F1F' />
                </ActionIcon>
              </Popover.Target>
              <Popover.Dropdown>
                <Stack spacing='xs'>
                  <Button
                    fullWidth
                    key='en'
                    onClick={() => changeLanguage('en')}
                    className={
                      currentLanguage === 'en-EN'
                        ? styles.submitButton
                        :  styles.unSubmitButton
                    }
                  >
                    English
                  </Button>
                  <Button
                    fullWidth
                    key='ru'
                    onClick={() => changeLanguage('ru')}
                    className={
                      currentLanguage === 'ru-RU'
                        ? styles.submitButton
                        : styles.unSubmitButton
                    }
                  >
                    Русский
                  </Button>
                </Stack>
              </Popover.Dropdown>
            </Popover>
            {renderMainLinks(bottomLinks)}
          </div>
        </div>
        {menuOpen && (
          <div className={styles.mainLeft}>
            <Title order={4} className={styles.title}>
              {active}
              <ActionIcon
                onClick={() => {
                  setMenuOpen(!menuOpen), setIsHovered(!isHovered);
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                style={{
                  backgroundColor: isHovered
                    ? 'var(--as-secondary)'
                    : 'light-dark(var(--mantine-color-white), var(--mantine-color-dark-6))',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <IconArrowBigLeft color='#495057' />
              </ActionIcon>
            </Title>
            {renderLinks(linksMockdata[active] || [])}
          </div>
        )}
      </div>
    </nav>
  );
}
