import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../helpers';
import { BASE_URL_BOOKING_SERVICE, CHANNEL_GETALL } from '../../endpoints';
import { Method } from '../../types';
import { ChannelAllResponse } from './booking-siervice-channel.types';

export const bookingServiceChannelApi = createApi({
  reducerPath: 'bookingServiceChannelApi',
  tagTypes: ['bookingServiceChannel'],
  baseQuery: baseQueryWithAuth(BASE_URL_BOOKING_SERVICE),
  endpoints: builder => ({
    getChannelAllData: builder.query<ChannelAllResponse[], unknown>({
      query: params => ({
        url: CHANNEL_GETALL,
        method: Method.Get,
        params: params,
      }),
      keepUnusedDataFor: 0.001,
    }),
  }),
});

export const { useGetChannelAllDataQuery } = bookingServiceChannelApi;
