import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../helpers';
import { BASE_URL_DATA_WALL, BOOKING_SUMMARY_URL } from '../../endpoints';
import { Method } from '../../types';
import {
  BookingSummaryApiProps,
  BookingSummaryResponse,
} from './booking-summary.types';

export const bookingSummaryApi = createApi({
  reducerPath: 'bookingSummaryApi',
  tagTypes: ['bookingSummary'],
  baseQuery: baseQueryWithAuth(BASE_URL_DATA_WALL),
  endpoints: builder => ({
    getBookingSummaryData: builder.query<BookingSummaryResponse[], BookingSummaryApiProps>({
      query: ({ GuestId, HotelIds }) => {
        const params = new URLSearchParams();
        params.append('GuestId', GuestId);
        HotelIds.forEach(id => params.append('HotelIds', id));

        return {
          url: `${BOOKING_SUMMARY_URL}?${params.toString()}`,
          method: Method.Get,
        };
      },
      keepUnusedDataFor: 0.001,
    }),
  }),
});

export const { useGetBookingSummaryDataQuery } = bookingSummaryApi;