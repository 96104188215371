import React from 'react';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './reset.css';
import './global.css';
import { createRoot } from 'react-dom/client';
import { MantineProvider } from '@mantine/core';
import { StoreProvider } from 'app/providers/store';
import { Notifications } from '@mantine/notifications';
import './i18n';
import '@mantine/styles';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <StoreProvider>
    <MantineProvider
      theme={{
        colorScheme: 'light',
        primaryColor: 'blue',
        fontFamily: 'Raleway, sans-serif',
        headings: { fontFamily: 'Raleway, sans-serif' },
       
      }}
    >
      <BrowserRouter>
        <Notifications position='top-right' />
        <App />
      </BrowserRouter>
    </MantineProvider>
  </StoreProvider>,
);
