import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../helpers';
import { BASE_URL_GUEST_SERIVCE, GUEST_INFO } from '../../endpoints';
import { Method } from '../../types';
import { GuestInfoResponse, GuestsInfoApiProps } from './guest-info.types';

export const guestInfoApi = createApi({
  reducerPath: 'guestInfoApi',
  tagTypes: ['guestInfo'],
  baseQuery: baseQueryWithAuth(BASE_URL_GUEST_SERIVCE),
  endpoints: builder => ({
    getGuestInfoData: builder.query<GuestInfoResponse[], GuestsInfoApiProps>({
      query: params => ({
        url: GUEST_INFO,
        method: Method.Get,
        params: params,
      }),
      keepUnusedDataFor: 0.001,
    }),
  }),
});

export const { useGetGuestInfoDataQuery } = guestInfoApi;
