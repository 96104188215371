export const translationsDictionary = {
  'База гостей': 'Guest base',
  'Новый гость': 'New guest',
  'Фамилия': 'Last Name',
  'Имя': 'First Name',
  'Отчество': 'MiddleName',
  'Номер телефона': 'Phone Number',
  'Уровень лояльности': 'Loyalty Level',
  'Удален': 'Deleted',
  'Верифицирован': 'Verified',
  'Пол': 'Gender',
  'Женский': 'Female',
  'Мужской': 'Male',
  'Дата рождения': 'Date of Birth',
  'Гражданство': 'Nationality',
  'Кол-во ночей': 'Number of Nights',
  'Кол-во посещений': 'Number of Visits',
  'РФ': 'Russian Federation',
  'США': 'USA',
  
  'Разблокировать пользователя': 'Unblock user',
  'Подтвердите разблокировку пользователя вводом номера телефона:': 'Confirm user unblocking by entering the phone number:',

  'Обнуление баланса': 'Balance reset',
  'Вы уверены что хотите обнулить баланс внутреннего счета?': 'Are you sure you want to reset your internal account balance?',
  'Отменить': 'Cancel',
  'Подтвердить': 'Confirm',
  'Блокировка гостя': 'Blocking a guest',
  'Статус программы лояльности': 'Loyalty program status',
  
  'Баланс внутреннего счёта': 'Internal account balance',
  'Текущий баланс': 'Current balance',
  'Пополнить': 'Top up',
  'Новый статус': 'New status',
  'Списать': 'Write off',
  'Введите сумму пополнения': 'Enter the top-up amount',
  'Введите сумму списания': 'Enter the deduction amount',

  'Изменить номер телефона': 'Change phone number',
  'Текущий номер телефона': 'Current phone number',
  'Поле обязательное': 'Field required',
  'Новый номер телефона': 'New phone number',
  'Сохранить': 'Save',
  'Причина': 'Cause',
  
  'Подтверждение номера:': 'Number confirmation:',
  'Вы уверены, что хотите удалить этот комментарий?': 'Are you sure you want to delete this comment?',

  'Заметка о госте': 'Guest Note',
  'Осталось имволов: ': 'Characters left: ',
  'Номер не совпадает': 'Number does not match',
  'Удаление': 'Removal',
  'Подтвердите удаление профиля гостя вводом номера телефона:': 'Confirm deletion of the guest profile by entering your phone number:',
  
  'Добавить гостя': 'Add a guest',
  'Неверный формат email': 'Неверный формат email',
  'Номер телефона не должен содержать букв': 'The phone number must not contain letters',
  'Номер телефона обязателен': 'Phone number is required',
  'Неверный формат номера телефона. Используйте только цифры.': 'Invalid phone number format. Use numbers only.',
  'Номер телефона должен содержать 11 цифр': 'Phone number must contain 11 digits',

  'Блокировка пользователя': 'Blocking a user',
  'Подтвердите блокировку пользователя вводом номера телефона:': 'Confirm blocking the user by entering the phone number:',

  'Создан': 'Created',
  'Обновлен': 'Updated',
  'Был активен': 'Was active',
  'Код': 'Code',
  'Заблокирован': 'Blocked',
  'Да': 'Yes',
  'Нет': 'No',
  'Документы': 'Documents',
  'Проверены': 'Verified',
  'Не проверены': 'Not verified',
  'Действия': 'Actions',
  'Изменить карточку': 'Change card',
  'Смена номера телефона': 'Changing your phone number',
  'Обнулить баланс внутр. счета': 'Reset internal account balance',
  'Посмотреть фото': 'View photo',
  'Удалить профиль': 'Delete profile',
  'Личные данные': 'Personal information',
  'Текущий статус': 'Current status',

  'Дата': 'Date',
  'Отмена': 'Cancel',
  'Список гостей': 'Guest list',
  'Событие': 'Event',
  'Статус': 'Status',
  'Комментарии': 'Comments',
  'Начислено': 'Credited',
  'Списано': 'Debited',
  'Бронирование': 'Booking',
  'Даты': 'Dates', 
  'Номер': 'Number',
  'Ночей': 'Nights',
  'Покупка по внутреннему счету': 'Internal account purchase',
  'Первое бронирование': 'First booking',
  'Отмена бронирования': 'Booking cancellation',
  'Ручное изменение счета': 'Manual account update',
  'Назначение статуса при регистрации': 'Status assigned upon registration',
  'Изменения статуса по количеству визитов': 'Status change based on visits',
  'Изменения статуса по количеству ночей': 'Status change based on nights',
  'Ручное изменение статуса': 'Manual status update',
  'Нет доступных данных для отображения.': 'No data available for display.',

  'Редактировать гостя': 'Change guest',
  'Карточка гостя': 'Guest card',
  'У вас нет прав доступа на просмотр этой информации': 'You do not have permission to view this information',
  'Гость': 'Guest',
  'Пользователь': 'User',
  'Актуальные заметки': 'Current Notes',
  'Автор': 'Author',
  'Актуальных заметок нет': 'There are no current notes',
  'Данных о бронировании нет': 'Booking data not available',

  'Актуальные бронирования': 'Current Reservations',
  'Проживаний всего': 'Total Stays',
  'Статус в программе лояльности': 'Loyalty Program Status',
  'Заметки': 'Notes',
  'История статусов': 'Status history',
  'Внутренний счет': 'Internal account',
  'Комментарий': 'Comment',
  'Программа лояльности': 'Loyalty program',
  'Итого': 'Total',

  'Баланс': 'Balance',
  'Редактировать': 'Edit',
  'Редактировать номер телефона': 'Edit Phone Number',
  'Добавить': 'Add',
  'Удалить': 'Delete',
  'История': 'History',
  'Изменить': 'Change'
};
