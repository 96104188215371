import { routes } from 'shared/constants';
import { IconCalendarStats, IconHome2, IconLogout, IconUsers } from '@tabler/icons-react';

export interface LinkItem {
  label: string;
  link?: string;
}

export interface MainLinkItem extends LinkItem {
  icon: React.ElementType;
}

export const mainLinksMockdata: MainLinkItem[] = [
  { icon: IconHome2, label: 'Home', link: routes.home },
  { icon: IconCalendarStats, label: 'Guest' },
  { icon: IconUsers, label: 'Guests' },
  { icon: IconLogout, label: 'Logout' },
];
